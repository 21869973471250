// Create namespace
window.onboarding = window.onboarding || {};

function initProfileSaveButton() {
  console.log('initProfileSaveButton called');
  const firstNameInput = document.querySelector('input[name="user[first_name]"]');
  const lastNameInput = document.querySelector('input[name="user[last_name]"]');
  const saveButton = document.getElementById('profile-save-btn');

  console.log('Found elements:', {
    firstNameInput: !!firstNameInput,
    lastNameInput: !!lastNameInput,
    saveButton: !!saveButton
  });

  if (firstNameInput && lastNameInput && saveButton) {
    // Ensure button starts hidden
    saveButton.style.display = 'none';

    const checkInputs = () => {
      const hasInput = firstNameInput.value.trim() !== '' || lastNameInput.value.trim() !== '';
      console.log('Input check:', {
        firstNameValue: firstNameInput.value,
        lastNameValue: lastNameInput.value,
        hasInput: hasInput
      });
      
      // Use direct style manipulation instead of classList
      saveButton.style.display = hasInput ? 'block' : 'none';
    };

    // Check initial values
    checkInputs();

    // Add input event listeners
    ['input', 'change'].forEach(eventType => {
      firstNameInput.addEventListener(eventType, checkInputs);
      lastNameInput.addEventListener(eventType, checkInputs);
    });
  }
}

// Event listeners
document.addEventListener('DOMContentLoaded', initProfileSaveButton);
document.addEventListener('turbo:load', initProfileSaveButton);
document.addEventListener('turbo:render', initProfileSaveButton);

// Export functions to the namespace
window.onboarding = {
  initProfileSaveButton
}; 