// dropzoneModule.js

function destroyExistingDropzone(elementId) {
  try {
    // Remove 'form#' from elementId for querySelector
    const cleanId = elementId.replace('form#', '#');
    const element = document.querySelector(cleanId);
    
    if (!element) {
      console.log(`Element ${elementId} not found, skipping destroy`);
      return;
    }
    
    if (element.dropzone) {
      console.log(`Destroying existing dropzone for ${elementId}`);
      element.dropzone.destroy();
    }
  } catch (e) {
    console.log(`Error destroying dropzone for ${elementId}:`, e);
  }
}

function initializeDropzone(elementId, options = {}) {
  // Remove 'form#' from elementId for querySelector
  const cleanId = elementId.replace('form#', '#');
  const element = document.querySelector(cleanId);
  
  if (!element) {
    console.log(`Element ${elementId} not found, skipping initialization`);
    return null;
  }

  destroyExistingDropzone(elementId);
  
  const defaultConfig = {
    url: options.url || '/photos',
    uploadMultiple: options.uploadMultiple || false,
    addRemoveLinks: options.addRemoveLinks !== undefined ? options.addRemoveLinks : true,
    maxFiles: options.maxFiles || 12,
    acceptedFiles: options.acceptedFiles || 'image/*',
    dictInvalidFileType: options.dictInvalidFileType || 'This form only accepts images.',
    paramName: options.paramName || "photo[thumbnail]",
    dictRemoveFile: 'Delete',
    previewsContainer: options.previewsContainer,
    init: function() {
      // Video thumbnail handling
      if (options.handleVideo) {
        this.on("addedfile", function(file) {
          if (file.type.match('video.*')) {
            var video = document.createElement('video');
            var reader = new FileReader();

            reader.onload = function(e) {
              video.src = e.target.result;
              video.currentTime = 1;

              video.addEventListener('loadeddata', function() {
                var canvas = document.createElement('canvas');
                canvas.width = video.videoWidth / 4;
                canvas.height = video.videoHeight / 4;

                var ctx = canvas.getContext('2d');
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                file.previewElement.querySelector("img").src = canvas.toDataURL();
              });
            };

            reader.readAsDataURL(file);
          }
        });
      }

      this.on("success", function(file, response) {
        console.log("File uploaded successfully", response);
        if (window.util && window.util.photos) {
          window.util.photos.addToBySelector('.photos-index', response);
        }
      });
      
      this.on("error", function(file, errorMessage) {
        console.error("Error uploading file", errorMessage);
      });

      this.on("removedfile", function(file) {
        console.log(file);
      });

      if (options.additionalInit) {
        options.additionalInit.call(this);
      }
    }
  };

  try {
    return new Dropzone(element, { ...defaultConfig, ...options });
  } catch (e) {
    console.error(`Error initializing dropzone for ${elementId}:`, e);
    return null;
  }
}

function initDropzones() {
  //console.log("Initializing dropzones...");
  
  // Initial dropzone with 100ms delay
  setTimeout(() => {
    const dropzoneElement = document.getElementById('dropzone');
    if (dropzoneElement) {
      //console.log("Initializing main dropzone");
      initializeDropzone('form#dropzone', {
        url: '/photos',
        uploadMultiple: false,
        addRemoveLinks: true,
        maxFiles: 12,
        acceptedFiles: 'image/*',
        paramName: "photo[thumbnail]",
        dictInvalidFileType: 'This form only accepts images.'
      });
    }
  }, 100);

  // Album and Project dropzones with 300ms delay
  setTimeout(() => {
    const albumElement = document.getElementById('dropzone_album');
    if (albumElement) {
      console.log("Initializing album dropzone");
      initializeDropzone('form#dropzone_album', {
        url: '/photos',
        uploadMultiple: false,
        addRemoveLinks: true,
        maxFiles: 12,
        acceptedFiles: 'image/*',
        paramName: "photo[thumbnail]",
        dictInvalidFileType: 'This form only accepts images.'
      });
    }

    const projectElement = document.getElementById('dropzone_project');
    if (projectElement) {
      console.log("Initializing project dropzone");
      initializeDropzone('form#dropzone_project', {
        url: '/photos',
        uploadMultiple: false,
        addRemoveLinks: true,
        maxFiles: 12,
        acceptedFiles: "image/jpeg,image/png,image/gif,video/mp4,video/avi,video/quicktime",
        paramName: "photo[file]",
        dictInvalidFileType: 'This form only accepts images.',
        previewsContainer: "#dropzone_project_previews",
        handleVideo: true,
        autoProcessQueue: true
      });
    }
  }, 300);
}

// Export the module
window.dropzoneModule = {
  init: initDropzones,
  initializeDropzone: initializeDropzone,
  destroyExistingDropzone: destroyExistingDropzone
}; 