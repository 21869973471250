$(document).on('change', '#album_albumable_type', function() {
  const selectedType = $(this).val()
  const idField = $('.albumable-id-field')
  const idSelect = $('#album_albumable_id')
  
  // Clear and hide by default
  idSelect.empty()
  idField.addClass('d-none')
  
  if (selectedType === 'User') {
    // Personal album - no need to show selector
    idSelect.append(new Option('Personal Album', currentUserId))
    idSelect.prop('required', false) // Remove required when hidden
    idField.addClass('d-none')
  } else {
    // Show the field for org/project selection
    idField.removeClass('d-none')
    idSelect.prop('required', true) // Add required when shown
    
    // Load appropriate options via AJAX
    $.get('/albums/get_albumable_options', { type: selectedType })
      .done(function(data) {
        idSelect.append(new Option('Select...', ''))
        data.forEach(function(item) {
          idSelect.append(new Option(item.name, item.id))
        })
      })
  }
}) 