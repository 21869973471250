// app/javascript/packs/posts.js

import 'bootstrap';
import Rails from '@rails/ujs';


import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
//import PhotoSwipeDynamicCaption from 'https://unpkg.com/photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.esm.js';




window.util = window.util || {};
// Function to find the first element with data-show="true"
function openFirstImageWithShow() {
    const gallery = document.querySelector('.my-gallery');
    const children = gallery.querySelectorAll('a[data-show="true"]');
    
    if (children.length > 0) {
        // Open the first image that has data-show="true"
        const firstIndex = Array.from(gallery.children).indexOf(children[0]);
        lightbox.loadAndOpen(firstIndex);
    }
}


// Define the 'photos' object within the 'util' namespace
window.util.photos = {
    bindLightbox: function() {
                
        const lightbox = new PhotoSwipeLightbox({
            gallery: '.my-gallery',
            children: 'a.lightbox',
            pswpModule: () => import('photoswipe/dist/photoswipe.esm.js'),
            getCaptionHTMLFn: (slide) => {
                return "Hard coded caption";
                // Return the caption content for the current slide
                console.log('Caption:', slide.data.pswpCaption);
                return slide.data.pswpCaption || '';
            }
        });

        // Register the caption element
        lightbox.on('uiRegister', function() {
            lightbox.pswp.ui.registerElement({
                name: 'custom-caption',
                order: 9, // Order in the UI
                isButton: false,
                appendTo: 'wrapper',
                html: '<div class="pswp__custom-caption"></div>',
                onInit: (el, pswp) => {
                    pswp.on('change', () => {
                        const currSlide = pswp.currSlide;
                        el.innerHTML = currSlide.data.pswpCaption || ''; // Use the data-pswp-caption attribute
                    });
                }
            });
        });
        
        lightbox.init();
        // Open the first image with data-show="true" on page load
        //window.onload = openFirstImageWithShow;
        function openFirstImageWithShow(gallery) {
            if (!gallery) return;

            const children = gallery.querySelectorAll('a');
            const showElements = Array.from(children).filter(child => child.dataset.show === 'true');
            
            if (showElements.length > 0) {
                const firstShowElement = showElements[0];
                const firstIndex = Array.from(children).indexOf(firstShowElement);

                const lightbox = new PhotoSwipeLightbox({
                    gallery: gallery,
                    children: 'a.lightbox',
                    pswpModule: () => import('photoswipe/dist/photoswipe.esm.js')
                });
                lightbox.init();

                // Ensure the image is loaded before opening it
                const img = new Image();
                img.src = firstShowElement.href;

                img.onload = function() {
                    lightbox.loadAndOpen(firstIndex);
                };
            }
        }

        // Initialize lightboxes and handle Turbo/Turbolinks navigation
        function initializeLightboxes() {
            const galleries = document.querySelectorAll('.my-gallery');
            
            galleries.forEach(gallery => {
                openFirstImageWithShow(gallery);
            });
        }

        // Trigger the initialization on Turbo/Turbolinks load and on initial page load
        document.addEventListener('turbo:load', initializeLightboxes);
        // For Turbolinks, use the following line instead:
        // document.addEventListener('turbolinks:load', initializeLightboxes);

        // Also initialize on the initial page load
        initializeLightboxes();

    },
    removePhoto: function(photoId)
    {
        $.ajax({
            type: "DELETE", // Use the DELETE HTTP method
            url: "/photos/" + photoId, // Adjust this to your actual photo resource endpoint
            success: function (data) {
            console.log("File removed successfully", data);
            },
            error: function (error) {
            console.error("Error removing file", error);
            },
        });
    },
    refreshIndex: function() {
        $.ajax({
            type: "GET",
            url: "/photos/", // Adjust this to your actual photo resource endpoint
            success: function (data) {
            console.log("File removed successfully", data);
            },
            error: function (error) {
            console.error("Error removing file", error);
            },
        });
    },
    // add a photo to the selector, called after dropzone upload
    addToBySelector: function(selector,result)
    {
        // if result is json, fetch html
        let notification;
        if(result.notification) {
            notification = siteUi.showNotification(result.notification,7000);         
        }
        if(result.html) {
            $(selector).prepend(result.html)
        }
    },
    // get org Photos, put them in the panel
    loadOrgPhotos: function(orgId, dataType='json') {
        let panelTarget = $('#photos-panel.side-panel')
        if(panelTarget && !(panelTarget.hasClass('photos-loading') || panelTarget.hasClass('photos-loaded'))) {
            panelTarget.addClass('photos-loading');
            panelTarget.html( '<div class="text-center"><span class="spinner-border spinner-border-sm" role="status"></span> Loading...</div>');
        
            $.ajax({
                type: "GET",
                url: `/org/${orgId}/photos.js?panel=true`, 
                dataType: dataType, // script, html, or json
                success: function (data) {

                    panelTarget.addClass('photos-loaded');
                    panelTarget.removeClass('photos-loading');
                    

                    console.log("Photos loaded successfully", data);
                    if(dataType == 'html') {
                        $(selector).prepend(data)
                    }
                    else if(dataType == 'json') {
                        console.log('json data', data);
                        if(data.parts && data.parts.photos) {
                            panelTarget.html(data.parts.photos);
                        }
                        else {
                            console.log('no photos found in json data', data);
                        }
                    }
                    else {
                        eval(data);
                    }
                },
                error: function (error) {
                    console.error("Error loading photos", error);
                    panelTarget.removeClass('photos-loading');
                    panelTarget.prepend('<div class="text-center alert alert-danger">Error loading photos</div>');
                },
            });
        }
        else {
            console.log('panel is loading or loaded');

        }
        
    },
    guessOrgId: function() {
        // get the org id from the url, such as /orgs/123 or /orgs/123/events, or /orgs/123/books?filter=free
        const url = window.location.href;

        // /orgs/3 and /orgs/3/events/6/photos/new should BOTH get 3 as the orgId
        //var orgId = url.includes('/org/') ? url.split('/org/')[1] : null;
        //if(orgId) {
        //    orgId = orgId.split('/')[0];
        //}
        var orgId = null;
        var orgMatch = url.match(/\/org(?:s)?\/(\d+)/);
        if (orgMatch) {
            orgId = orgMatch[1];
        }
        return orgId;
    },
    guessOrgAndLoadPhotos: function() {
        const url = window.location.href;
        if(url.includes('/orgs/')) {
            const orgId = window.util.photos.guessOrgId();
            window.util.photos.loadOrgPhotos(orgId);
        }
    },
    bindPanel: function() {
        let panel = document.getElementById('photos-panel');

        // if not found, create it and select it
        if(!panel) {
            panel = document.createElement('div');
            panel.id = 'photos-panel';
            panel.classList.add('side-panel');
            document.body.appendChild(panel);
            
            
            //if we want to preload
            //window.util.photos.guessOrgAndLoadPhotos();
          
        }

        let togglePanelBtn = null;
        togglePanelBtn = document.getElementById('toggle-panel-btn');
        if(!togglePanelBtn) {
           
            console.log('no togglePanelBtn found');

            // data-toggle-target="photos-panel"
            togglePanelBtn = document.createElement('button');
            togglePanelBtn.id = 'toggle-panel-btn';
            togglePanelBtn.classList.add('toggle-panel', 'toggle-panel-btn', 'btn', 'rounded-0', 'rounded-left', 'd-none');
            togglePanelBtn.innerHTML = '<i class="fa fa-images"></i>';
            document.body.appendChild(togglePanelBtn);
        }

        
        if(togglePanelBtn) {
            togglePanelBtn.addEventListener('click', function () {
                window.util.photos.guessOrgAndLoadPhotos();
                panel.style.right = panel.style.right === '0px' ? `-${panel.offsetWidth}px` : '0px';
            });
        }
        else {
            console.log('no togglePanelBtn found');
        }
    
    

        //const toggleBtn = document.getElementById('toggle-panel-btn');
        const toggleBtns = document.querySelectorAll('.toggle-panel')
        if(toggleBtns.length > 0) {
            toggleBtns.forEach((btn) => {
                
                btn.addEventListener('click', function () {
                    window.util.photos.guessOrgAndLoadPhotos();
                    //TODO: panel should be retrieved by btn data-toggle-target
                    const panelWidth = panel.offsetWidth;
                
                    // Toggle the panel by adjusting the right position
                    const panelOpen = panel.style.right === '0px';
                    panel.style.right = panelOpen ? `-${panelWidth}px` : '0px';
                    let panelIsClosed = false;
                    //if(btn.classList.contains('toggle-panel-btn'))                
                    toggleBtns.forEach((btnToStyle) => {
                        if(panelOpen)
                        {
                            btnToStyle.classList.remove('panelOpen');
                            panelIsClosed = true;
                        }
                        else {
                            btnToStyle.classList.add('panelOpen');
                        }
                    });                
                    if(!panelIsClosed) {
                        let togglePanelBtn = document.getElementById('toggle-panel-btn');
                        if(togglePanelBtn) {
                            togglePanelBtn.classList.remove('d-none');
                        }
                    }
                    else {
                        setTimeout(() => {
                            let togglePanelBtn = document.getElementById('toggle-panel-btn');
                            if(togglePanelBtn) {
                                togglePanelBtn.classList.add('d-none');
                            }
                        }, 2000);
                    }

                });
            })
        }
        else {
            console.log('no toggleBtns found');
        }
       
        
    },
    bindDraggables: function() {
        console.log('inside bindDraggables');
    
        const draggableLists = document.querySelectorAll('.draggable-list');
        const dropSections = document.querySelectorAll('.drop-section');
    
        draggableLists.forEach(function(draggableList) {
          console.log('Initializing Draggable for:', draggableList);
          const draggable = new Draggable.Droppable(draggableList, {
            draggable: '.draggable-item',
            dropzone: dropSections,
          });
    
          draggable.on('drag:stop', function(event) {
            console.log('drag stopped');
            const draggedItemId = event.data.originalSource.dataset.id;
            const dropSection = event.dropzone;
            console.log('Dropped photo with ID:', draggedItemId, 'into section:', dropSection);
            window.util.photos.handleDroppedPhoto(draggedItemId, dropSection);
          });
        });
      },
    // Your custom function to handle the dropped photo
    handleDroppedPhoto: function (photoId, dropSection) {
      // Add your logic here based on the dropped photo ID and drop section
      console.log('Dropped photo with ID:', photoId, 'into section:', dropSection);
      // You can make an AJAX request or perform any other actions as needed
    }
}
/*
// Exports
window.util.photos = {
    removePhoto, refreshIndex, addToBySelector
};
*/ 